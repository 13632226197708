const env = {
  appName: 'opened',

  environment: process.env.NEXT_PUBLIC_APP_ENV,

  get baseUrl() {
    if (env.isProduction) {
      return 'https://opened.co';
    } else if (env.isStaging) {
      return 'https://opened.dev';
    }
    return 'http://localhost:3000';
  },

  get isDevelopment() {
    return env.environment === 'development';
  },
  get isTest() {
    return env.environment === 'test';
  },
  get isStaging() {
    return env.environment === 'staging';
  },
  get isProduction() {
    return env.environment === 'production';
  },

  get isBrowser() {
    return typeof window !== 'undefined';
  },
  get isLocal() {
    return env.isDevelopment || env.isTest;
  },
};

export default env;
